import { Injectable } from '@angular/core';
import {
  FilterTypesEnum,
  TcConfigTypes,
  TcDataProviderConfig,
  TcDataProviderType,
  TcSmartGridConfig,
} from '@tc/abstract';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  getTcData,
  getTcDataByStoreKey,
  getTcWriteDataProvider,
  initTcListDataStore,
  TcDataService,
} from '@tc/data-store';
import { Elevage } from '../modules/elevage/interfaces/elevage.interface';
import { selectedElevageStoreFields } from '../modules/elevage/types/elevage.types';
import { select, Store } from '@ngrx/store';
import { setCurrentElevage } from '../modules/elevage/store/elevage.actions';
import { Territoire } from '../modules/territoire/interfaces/territoire.interface';
import { TerritoireGroupe } from '../modules/territoire/interfaces/territoire-groupe.interface';
import { Secteur } from '../modules/territoire/interfaces/secteur.interface';
import { NgRxTcGridState, TcTranslateService } from '@tc/core';
import { DualChoice } from '../typings/dual-choice.enum';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { hasValue } from '@tc/utils';
import { selectValueByKey } from '@tc/store';

@Injectable({
  providedIn: 'root',
})
export class ElevageService {
  dataStore$: Observable<NgRxTcGridState>;

  constructor(
    private store$: Store<any>,
    private dataService: TcDataService,
    private readonly translate: TcTranslateService
  ) {
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  /**
   * This method inits elevage-grid data provider with minimal setup needed to save if no data provider was already in the store.
   * Use case is if a updateItem event is triggered to save something in the current elevage and the list was never loaded
   * this minimal configuration will take over to not block the save process.
   */
  public async initElevageGridWithMinimalSetup() {
    const storeKey = 'elevage-grid';

    // Check if a write data provider is already defined for elevage-grid
    const dataProvider = await selectValueByKey(
      getTcWriteDataProvider,
      this.dataStore$,
      storeKey
    );

    if (!dataProvider) {
      // No data provider, elevage-grid was never launched. Instanciate it with minimal setup to be able to launch a updateItem event.
      const config: TcSmartGridConfig = {
        configType: TcConfigTypes.TcGrid,
        storeKey: storeKey,
        gridOptions: {},
        dataProvider: {
          configType: TcConfigTypes.TcDataProvider,
          providerType: TcDataProviderType.BreezeJs,
          dataSet: 'Elevage',
        },
        columns: [],
      };

      this.store$.dispatch(
        initTcListDataStore({ storeKey: storeKey, listConfig: config })
      );
    }
  }

  public async setStoreCurrentElevage(elevageNumero: string) {
    const fields = selectedElevageStoreFields;

    const dataProvider: TcDataProviderConfig = {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Elevage',
      fields: fields,
      filter: {
        filters: [
          {
            key: 'numero',
            value: elevageNumero,
            filterType: FilterTypesEnum.Equal,
          },
        ],
      },
      transformFn: (result: any[]) => {
        const formattedElevages = this.formatElevages(result);
        return formattedElevages;
      },
    };

    const dataService = this.dataService.getService<any>(
      'Elevage',
      dataProvider
    );

    const fullElevage = await dataService.getData(0, 1);

    this.store$.dispatch(
      setCurrentElevage({ currentElevage: fullElevage.data[0] })
    );
  }

  private formatElevages(elevages: Elevage[]) {
    //Temporary, while waiting to have valid data inside the DB. Some mandatory fields are currently empty inside the DB which is causing validation errors when updating
    elevages.forEach((entry: any) => {
      entry.geographie = entry.geographie ? entry.geographie : ' ';
      entry.territoire = entry.territoire ?? new Territoire();
      entry.territoire.ID = entry.territoire.ID ?? 0;
      entry.territoire.numero = entry.territoire.numero ?? ' ';
      entry.territoire.libelle = entry.territoire.libelle ?? ' ';
      entry.territoire.groupe =
        entry.territoire.groupe ?? new TerritoireGroupe();
      entry.territoire.groupe.numero = entry.territoire.groupe.numero ?? ' ';
      entry.territoire.groupe.libelle = entry.territoire.groupe.numero ?? ' ';
      entry.territoire.groupe.secteur =
        entry.territoire.groupe.secteur ?? new Secteur();
      entry.territoire.groupe.secteur.numero =
        entry.territoire.groupe.secteur.numero ?? ' ';
      entry.territoire.groupe.secteur.libelle =
        entry.territoire.groupe.secteur.libelle ?? ' ';
      entry.reglement = entry.reglement ?? {
        intitule: ' ',
        condition: 0,
        nbJours: 0,
      };
      entry.reglement.intitule = entry.reglement.intitule
        ? entry.reglement.intitule
        : ' ';
      entry.reglement.condition = entry.reglement.condition ?? 0;
      entry.reglement.nbJours = entry.reglement.nbJours ?? 0;
      entry.syntheseFinanciere.factures =
        entry.syntheseFinanciere.factures?.map((facture) => ({
          ...facture,
          numeroDocument: facture.numeroDocument ? facture.numeroDocument : ' ',
        }));

      entry.documents.forEach((doc) => {
        if (doc.prescripteur)
          doc.prescripteur._id = doc.prescripteur._id
            ? doc.prescripteur._id
            : ' ';
        if (doc.souche) doc.souche._id = doc.souche._id ? doc.souche._id : ' ';

        doc.ligne.forEach((ligne) => {
          if (ligne.depot) {
            ligne.depot._id = ligne.depot._id ? ligne.depot._id : ' ';
            ligne.depot.nom = ligne.depot.nom ? ligne.depot.nom : ' ';
          }
          if (ligne.prescripteur)
            ligne.prescripteur._id = ligne.prescripteur._id
              ? ligne.prescripteur._id
              : null;
        });
      });
      //

      entry.syntheseFinanciere.commandesEnCoursLabel = entry.syntheseFinanciere
        ?.commandesEnCours
        ? this.translate.instant('yes')
        : this.translate.instant('no');
      entry.syntheseFinanciere.devisEnCoursLabel = entry.syntheseFinanciere
        ?.devisEnCours
        ? this.translate.instant('yes')
        : this.translate.instant('no');

      entry.editionFacturePapier =
        entry.editionFacturePapier?.toLowerCase() ===
        DualChoice.Yes.toLowerCase()
          ? this.translate.instant('yes')
          : entry.editionFacturePapier?.toLowerCase() ===
              DualChoice.No.toLowerCase() || !entry.editionFacturePapier
          ? this.translate.instant('no')
          : entry.editionFacturePapier;
    });

    return elevages;
  }
}
